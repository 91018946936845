<template>
  <div class="millList">
    <div class="mill-box-body" v-for="(item,index) in machines" :key="index">
      <div class="mill-box-body-left">
        <img src="../../assets/mill/位图@2x.png" alt />
      </div>
      <div class="mill-box-body-right">
        <div class="mill-box-body-right-title1">
          <img src="../../assets/mill/ETH-5@2x.png" alt />
          <span class="mill-box-body-right-title1-name">{{item.machineName}}{{item.batchNum}}</span>
          <div class="mill-box-body-right-title1-type">
            <span v-if="item.recovery == 1">未回本</span>
            <span v-if="item.recovery == 2">已回本</span>
          </div>
        </div>
        <p class="mill-box-body-right-title">
          <span>实付金额：{{item.price}}</span>
        </p>
        <p class="mill-box-body-right-title">
          <span>矿机编号：{{item.kjNo}}</span>
        </p>
        <p class="mill-box-body-right-title">
          <span>已挖数量：{{item.allReady}}</span>
        </p>
      </div>
    </div>
    <div class="mill-box-more">没有更多数据了～</div>
  </div>
</template>

<script>
export default {
  name: "millList",
  data() {
    return { machines: [], id: "", allReady: "" };
  },
  created() {
    this.id = this.$route.query.id;
    this.allReady = this.$route.query.allReady;
    this.getCoinList();
  },
  methods: {
    getCoinList() {
      const params = {
        id: this.id,
        allReady: this.allReady
      };
      this.$request
        .post("/user/machineList", params)
        .then(
          res => {
            if (res.code == 0) {
              this.machines = res.data;
            } else {
              this.$toast(res.msg);
              if (res.code == 1018) {
                this.$router.push("/login");
              }
            }
            // 执行成功的回调函数
          },
          error => {
            console.log(error);
            // 执行失败的回调函数
          }
        )
        .catch(() => {});
    }
  },
  mounted() {},
  computed: {},
  components: {}
};
</script>
<style scoped lang="scss">
.millList {
  padding: 20px 15px;
  background: #f8f8f8;
  min-height: 100vh;
  .mill-box-body {
    display: flex;
    text-align: left;
    height: 120px;
    background: #ffffff;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 13px 8px;
    margin-bottom: 20px;
    .mill-box-body-left {
      img {
        width: 90px;
        height: 90px;
      }
    }
    .mill-box-body-right {
      margin-left: 4px;

      .mill-box-body-right-title1 {
        display: flex;
        align-items: center;
        img {
          width: 18px;
        }
        .mill-box-body-right-title1-name {
          max-width: 160px;
          overflow: hidden;
          text-overflow: ellipsis; //显示 ...
          white-space: nowrap; //不换行
          display: inline-block;
          margin: 0 7px 0 2px;
          font-size: 17px;
          font-family: "HelveticaCondensedBold", "HelveticaCondensed";
          font-weight: 700;
          color: #4a4a4a;
          line-height: 21px;
        }
        .mill-box-body-right-title1-type {
          width: 40px;
          height: 18px;
          background: #e7f1ff;
          border-radius: 2px;
          text-align: center;
          span {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #1677ff;
            line-height: 18px;
          }
        }
      }
      .mill-box-body-right-title {
        margin: 5px 0 0 4px;
        span {
          font-size: 13px;
          font-family: PingFangSC-Regular, PingFang SC;
          color: #93a5b2;
          line-height: 18px;
        }
      }
    }
  }
  .mill-box-more {
    margin: 20px 0 60px;
    font-size: 12px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #cccccc;
    line-height: 17px;
  }
}
</style>